$font-regular: 'Gilroy-Regular';
$font-semibold: 'Gilroy-Semibold';
$font-bold: 'Gilroy-Bold';

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('../assets/fonts/Gilroy-Regular.ttf');
}

@font-face {
  font-family: 'Gilroy-Semibold';
  src: url('../assets/fonts/Gilroy-Medium.ttf');
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('../assets/fonts/Gilroy-Bold.ttf');
}
