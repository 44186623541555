@import '../../styles/index.scss';

.section {
  padding: 4em 128px;

  @include up-to($desktop-medium) {
    padding: 80px 20px;
  }
  @include up-to($tablet-small) {
    padding: 20px;
  }
}

.home {
  background-color: $medium_grey;

  .hero {
    width: 100%;
    padding: 0px;
    height: calc(100vh - 69px);
    background: url('../../assets/images/hero-banner.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-left: 100px;

    @include up-to($tablet-small) {
      background-position: 85% bottom;
    }

    &-content {
      top: 50%;
      max-width: 560px;
      position: absolute;
      transform: translateY(-50%);
    }

    @include up-to($tablet-small) {
      // height: 100vh;
      padding: 0 20px;
      height: calc(100vh - 70px);
    }

    &-title {
      font-size: 60px;
      line-height: 66px;
      margin-bottom: 30px;
      font-family: $font-bold;
      color: $dark_blue;

      @include up-to($phone-landscape) {
        font-size: 46px;
        line-height: 50px;
      }
    }

    &-subtitle {
      margin: 20px 0;
      color: $white;
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
      opacity: 0.8;

      &--bold {
        font-weight: 500;
        opacity: 1;
      }
    }

    .watch-video-btn {
      border: none;
      font-size: 18px;
      margin-top: 20px;
      border-radius: 4px;
      font-family: $font-semibold;
      padding: 12px 34px 12px 20px;
      box-shadow: 0px 6px 30px rgba(3, 26, 92, 0.18);

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 50%;
        top: 0;
      }
    }
  }

  .classes {
    padding: 4em 128px;

    @include up-to($desktop-medium) {
      padding: 80px 20px;
    }

    @include up-to($tablet-small) {
      padding: 40px 20px;
    }

    &-grid {
      grid-gap: 30px;
    }
  }

  .banner {
    padding: 0 60px;

    @include up-to($tablet-small) {
      padding: 0px 20px 30px;
    }

    max-height: 374px;
    overflow: hidden;
    border-radius: 10px;

    &-img {
      width: 100%;
      height: 100%;
    }
  }

  .what-we-offer {
    @extend .section;
    .title {
      text-align: center;
      color: $dark_blue;
      font-size: 36px;
    }

    .usp-container {
      grid-gap: 30px;
      margin-top: 60px;

      @include up-to($tablet-small) {
        margin-top: 30px;
      }
    }
  }

  .testimonials {
    margin-top: 14px;
    padding: 64px 0;
    background: rgba($blue, 0.1);

    @include up-to($tablet-large) {
      margin-top: 60px;
    }

    .title {
      font-size: 36px;
      color: $dark_blue;
      text-align: center;
    }

    &-container {
      padding-left: 158px;

      @include up-to($tablet-large) {
        padding-left: 0;
      }

      .owl-stage-outer {
        padding: 40px 0px;
      }
    }

    .owl-nav {
      text-align: right;
      margin-right: 120px;

      @include up-to($tablet-small) {
        text-align: center;
        margin: 0px auto;
      }

      button {
        font-size: 40px;
        background: transparent;
        border: none;
      }
    }
  }

  .video-modal {
    width: 75%;
    padding: 0;
    background-color: $black;
    overflow-y: hidden;

    video {
      width: 100%;
      height: 100%;
    }
  }
}
