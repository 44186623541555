@import '../../styles/index.scss';

.section {
  padding: 100px;

  @include up-to($tablet-small) {
    padding: 60px 30px;
  }
}

.partners {
  .hero {
    &-section {
      @extend .section;
      display: flex;
      align-items: center;

      @include up-to($tablet-small) {
        flex-direction: column;
      }
    }

    &-title {
      font-family: $font-bold;
      font-size: 42px;

      &--pink {
        color: $pink;
      }

      &--purple {
        color: $purple;
      }

      &--green {
        color: $green;
      }

      &--blue {
        color: $blue;
      }
    }

    &-description {
      margin-top: 48px;
      font-size: 18px;
      line-height: 30px;

      @include up-to($tablet-small) {
        margin-top: 20px;
      }
    }

    &-content {
      margin-right: 96px;
      flex: 0.6;

      @include up-to($tablet-small) {
        flex: 1;
        margin: 0;
      }
    }

    &-img {
      flex: 0.5;
      text-align: right;
      @include up-to($tablet-small) {
        flex: 1;
        margin-top: 30px;
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        max-width: 420px;
        border-radius: 0px 30px 30px 30px;
      }
    }
  }
}

.partners-container {
  .usp-item:nth-child(even) {
    flex-direction: row-reverse;

    @include up-to($tablet-small) {
      padding: 40px;
      flex-direction: column-reverse;
    }
  }

  .usp-item:nth-child(odd) {
    .usp-item-right {
      text-align: right;
    }
  }
}

.resource-partners {
  padding: 100px;

  @include up-to($tablet-small) {
    padding: 60px 20px;
  }

  &-title {
    text-align: center;
    color: $dark_green;
    font-size: 32px;
  }

  &-grid {
    max-width: 600px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 70px;
    text-align: center;
    margin-top: 50px;
    text-align: center;

    @include up-to($tablet-small) {
      grid-gap: 20px;
      max-width: 100%;
    }

    @include up-to($phone-landscape) {
      grid-template-columns: repeat(2, 1fr);
    }

    .img-container {
      text-align: center;
    }

    img {
      width: 100%;
      height: 100%;
      max-width: 200px;
      max-height: 200px;
      min-width: 100px;
      min-height: 100px;
      object-fit: contain;

      @include up-to($phone-landscape) {
        max-width: 140px;
        max-height: 140px;
      }
    }
  }
}

.skills-item {
  text-align: center;
  padding: 60px 120px;

  @include up-to($desktop-medium) {
    padding: 36px 0;
  }

  &--green {
    background-color: rgba($green, 0.15);
  }

  &--blue {
    background-color: rgba($blue, 0.15);
  }

  .first-img {
    width: 70%;
    height: 70%;
  }

  &-second {
    display: flex;
    align-items: center;
    margin-bottom: 200px;

    @include up-to($tablet-small) {
      flex-direction: column;
    }
  }

  .img {
    flex: 0.4;
    text-align: left;
    height: 300px;

    img {
      width: 480px;
      height: 500px;
      position: absolute;
      z-index: 1;

      @include up-to($tablet-small) {
        position: relative;
        height: 100%;
        width: 100%;
      }
    }
  }

  .content {
    margin-left: 40px;
    font-size: 18px;
    line-height: 28px;
    flex: 0.6;
    text-align: left;
  }
}

.pdf-iframe {
  width: 100%;
  height: 100%;
}

// Mobile

.mobile {
  .hero {
    &-section {
      @extend .section;
      display: flex;
      align-items: center;
      padding-right: 0;

      @include up-to($tablet-large) {
        flex-direction: column;
        padding: 50px;
        text-align: center;
      }
    }

    &-description {
      width: 80%;

      @include up-to($tablet-large) {
        width: 100%;
      }
    }

    &-title {
      font-family: $font-bold;
      font-size: 42px;

      &--pink {
        color: $pink;
      }

      &--purple {
        color: $purple;
      }

      &--green {
        color: $green;
      }

      &--blue {
        color: $blue;
      }
    }

    &-description {
      margin-top: 48px;
      font-size: 18px;
      line-height: 30px;
    }

    &-second-img {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-item {
    background-color: $light_blue;
    display: flex;
    justify-content: space-between;

    &-left {
      h3 {
        font-size: 24px;
      }
    }
  }
}
