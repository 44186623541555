@import '../../styles/index.scss';

.usp-card {
  background-color: $light_grey;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  border-radius: 30px;
  padding: 30px;
  text-align: center;

  &-title {
    font-size: 26px;
    margin-top: 22px;
  }

  &-subtitle {
    margin-top: 20px;
    font-size: 18px;
    line-height: 26px;
    min-height: 150px;
  }

  .insights-btn {
    margin-top: 20px;
    display: inline-block;
    border: 1px solid $green;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 14px 34px;
    font-size: 18px;
    color: $green;
    background: $light_grey;
  }

  &-header {
    height: 100px;
  }

  .icon-container {
    position: absolute;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
