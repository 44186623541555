@import '../../styles/index.scss';

footer {
  // &::before {
  //   content: '';
  //   position: absolute;
  //   height: 40%;
  //   width: 100%;
  //   top: -60px;
  //   background-color: $medium_grey;
  // }

  .footer-main {
    padding: 0 60px;
    margin-top: 60px;

    @include up-to($tablet-large) {
      padding: 0 10px;
    }
  }

  @include up-to($tablet-small) {
    padding: 0 10px;
  }

  .membership {
    &-container {
      background-color: $dark_blue;
      background: url('../../assets/images/footer-bg.png') center/cover;
      text-align: center;
      color: $white;
      padding: 60px;

      @include up-to($phone-landscape) {
        padding: 60px 20px;
      }
    }

    &-content {
      max-width: 560px;
      margin: 0 auto;
    }

    &-title {
      font-family: $font-bold;
      font-size: 34px;
    }

    &-subtitle {
      margin-top: 24px;
      font-size: 16px;
      line-height: 22px;
      opacity: 0.7;
    }

    &-join-btn {
      margin-top: 56px;
      padding: 14px 36px;
      background: rgba(255, 255, 255, 0.15);
      box-shadow: 0px 6px 30px rgba(86, 195, 251, 0.18);
      border-radius: 7px;
      border: none;
      color: $light_grey;
      font-size: 16px;
    }
  }

  .copy-text {
    padding: 0 40px;
    margin-top: 100px;
    margin-bottom: 40px;

    @include up-to($tablet-large) {
      margin-top: 40px;
      padding: 0 10px;
    }
  }
}

.footer {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 40px;

  @include up-to($tablet-medium) {
    flex-direction: column;
    margin-top: 10px;
    padding: 0 10px;
  }

  .logo-img {
    max-width: 140px;
    max-height: 140px;
  }

  .info {
    flex: 1;

    &-description {
      margin-top: 24px;
    }
  }

  .links {
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin-left: 100px;

    @include up-to($tablet-large) {
      margin-left: 50px;
    }

    @include up-to($tablet-medium) {
      margin-top: 30px;
      margin-left: 0;
    }

    @include up-to($phone-landscape) {
      flex-direction: column;
    }

    &-content {
      display: flex;
      flex-direction: column;

      a {
        margin: 12px 0;
        align-self: flex-start;
        font-size: 16px;
        color: rgba($black, 0.8);
      }
    }

    .link-title {
      font-size: 18px;
      font-family: $font-semibold;
    }

    &-left {
      flex: 1;
    }
  }

  .contact {
    flex: 1;

    @include up-to($phone-landscape) {
      margin-top: 30px;
    }
  }

  .address {
    // max-width: 120px;
  }
}
