@import '../../styles/index.scss';

.tabs {
  width: 100%;
  &-list {
    display: flex;
  }

  &-body {
    margin-top: 20px;
  }

  .tab-btn {
    padding: 10px 20px;
    background-color: $white;

    &-active {
      background-color: $primary_blue;
      color: $white;
    }
  }

  .tab-icon {
  }
}
