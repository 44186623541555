@import "../../styles/index.scss";

.download {
  padding: 0px 60px;

  &-left {
    padding: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-icon {
      width: 60px;
      margin: 32px;
    }
  }

  &-right {
    padding: 60px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &-input {
      width: 75% !important;
    }

    &-button {
      border: none;
      font-size: 16px;
      color: #fcfcfc;
      margin-top: 32px;
      padding: 14px 36px;
      border-radius: 7px;
      background: #031a5c;
      box-shadow: 0px 6px 30px rgba(86, 195, 251, 0.18);

      &--disabled {
        opacity: 0.8;
        cursor: not-allowed;
      }
    }
  }
}
