@import '../../styles/index.scss';

.usp-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 100px 120px;

  @include up-to($tablet-small) {
    padding: 40px;
    flex-direction: column-reverse;
    text-align: center;
  }

  &-left {
    flex: 1;

    @include up-to($tablet-small) {
      margin-top: 30px;
    }
  }

  &-title {
    color: $green;
    font-size: 36px;
  }

  .url {
    margin-top: 4px;
    display: inline-block;
    color: rgba($black, 0.7);
  }

  &-subtitle {
    margin-top: 30px;
    font-size: 18px;
  }

  .url-cta {
    margin-top: 18px;
    font-size: 18px;
    display: inline-block;
  }

  .row-center {
    display: flex;
    align-items: center;
    margin-top: 20px;

    a {
      margin: 0;
    }
  }

  .pipe {
    margin: 0 10px;
    font-size: 20px;
    margin-top: -6px;
    color: $light_grey2;
  }

  .insight-btn {
    margin-top: 30px;
    border: 1px solid $green;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 12px 38px;
    font-size: 16px;
    background: transparent;
  }

  &-right {
    flex: 1;

    img {
      width: 100%;
      height: 100%;
      max-width: 280px;
      max-height: 280px;
    }
  }

  .analytics-img {
    max-width: 400px;
    max-height: 400px;
  }
}
