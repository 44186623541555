@import '../../styles/index.scss';

.testimonial-card {
  width: 100%;
  background-color: $white;
  box-shadow: 6px 6px 33px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px;
  min-height: 400px;
  min-width: 280px;

  @include up-to($tablet-large) {
    min-width: 100%;
  }

  .image-info {
    display: flex;
    align-items: center;

    .image {
      max-width: 90px;
      max-height: 90px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .info {
      margin-left: 12px;
    }

    .name {
      font-size: 18px;
      font-family: $font-semibold;
    }

    .designation {
      color: $grey;
    }
  }

  .stars {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: flex-start;

    img {
      width: 100%;
      height: 100%;
      max-width: 24px;
      max-height: 24px;
    }
  }

  .content {
    color: $grey;
    margin-top: 22px;
    line-height: 24px;
  }
}
