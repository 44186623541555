@import './fonts.scss';
@import "~bootstrap/scss/bootstrap";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  font-family: $font-regular;

  &:focus {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-semibold;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}
