@import '../../styles/index.scss';

.section-padding {
  padding: 0 140px;

  @include up-to($desktop-medium) {
    padding: 0 30px;
  }

  @include up-to($tablet-small) {
    padding: 0 10px;
  }
}

.subjects {
  margin-top: 36px;

  &-list {
    @include up-to($phone-landscape) {
      overflow-x: auto;
    }
  }

  &-tab-btn {
    width: 100%;
    border: none;
    border-bottom: 10px solid rgba($light_grey2, 0.4);
    font-size: 18px;

    &-active {
      border-bottom-color: #c44241;
    }

    .tab-icon {
      max-width: 120px;
      max-height: 120px;
    }
  }
}

.grade-list {
  @include up-to($phone-landscape) {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 18px;

    ::-webkit-scrollbar {
      width: 0;
      display: none;
      height: 0;
      /* Remove scrollbar space */
      background: transparent;
      /* Optional: just make scrollbar invisible */
    }
  }
}

.grades-tabs-container {
  margin-top: 50px;

  .tabs-body {
    margin-top: 0;
  }

  .grade-tab {
    &-btn {
      width: 140px;
      height: 80px;
      border-radius: 10px 10px 0px 0px;
      border: none;

      &--purple {
        background-color: $purple;
      }
      &--blue {
        background-color: $blue;
      }
      &--red {
        background-color: $dark_red;
      }
      &--green {
        background-color: $green;
      }
      &--yellow {
        background-color: $yellow;
      }
      &--pink {
        background-color: $pink;
      }

      // div {
      //   min-width: 100px;
      // }

      @include up-to($tablet-small) {
        min-width: 100px;
        text-align: center;
      }

      .tab-title {
        color: $white;
        font-family: $font-semibold;
        font-size: 32px;
      }

      &:not(:first-child) {
        margin-left: 46px;

        @include up-to($tablet-large) {
          margin-left: 10px;
        }
      }

      &-active {
        &:after {
          content: '';
          z-index: 99;
          display: block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 100%;
          width: 0;
          height: 0;
          border-bottom: 10px solid transparent;
          border-top: 10px solid;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
        }
      }
    }
  }

  .active {
    &-purple {
      &:after {
        border-top-color: $purple;
      }
    }

    &-blue {
      &:after {
        border-top-color: $blue;
      }
    }

    &-red {
      &:after {
        border-top-color: $dark_red;
      }
    }

    &-green {
      &:after {
        border-top-color: $green;
      }
    }

    &-yellow {
      &:after {
        border-top-color: $yellow;
      }
    }

    &-pink {
      &:after {
        border-top-color: $pink;
      }
    }
  }
}

.grade {
  &-container {
    // background: linear-gradient(179.98deg, #99E4C7 0.02%, rgba(196, 196, 196, 0) 35.88%);
    background: linear-gradient(
      179.98deg,
      #b0d7f4 12%,
      #d8f0f2 38.88%,
      #fff 100%
    );

    min-height: 100vh;
  }

  &-body {
    padding: 36px 140px;
    margin-top: 0;

    @include up-to($phone-landscape) {
      margin-top: -18px;
    }

    @include up-to($desktop-medium) {
      padding: 36px 20px;
    }
  }

  &-info {
    padding: 0 60px;

    @include up-to($desktop-medium) {
      padding: 0 20px;
    }
  }

  &-title {
    font-family: $font-bold;
    color: $dark_blue;
    font-size: 30px;
  }

  &-subtitle {
    margin-top: 16px;
    font-size: 18px;
    line-height: 24px;
    opacity: 0.7;
  }
}

.topics-container {
  margin-top: 80px;
  grid-gap: 30px;

  &.three-col-grid {
    grid-gap: 30px;
  }

  @include up-to($tablet-small) {
    margin-top: 30px;
  }
}

.row {
  display: flex;
}

.subtopic-modal-content {
  padding: 20px;
  overflow-y: auto;

  .subtopic-title {
    color: $green;
    font-family: $font-semibold;
    font-size: 24px;
  }
}

.subtopics-all-list {
  margin-top: 20px;
  -moz-column-count: 2;
  -moz-column-gap: 40px;
  -webkit-column-count: 2;
  -webkit-column-gap: 40px;
  column-count: 2;
  column-gap: 40px;

  @include up-to($phone-landscape) {
    column-count: 1;
    -moz-column-count: 1;
    -webkit-column-count: 1;
  }

  .subtopic-item {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}
