$dark_blue: #031a5c;
$primary_blue: #2a4365;
$white: #fff;
$black: #000;
$orange: #f48b52;
$light_grey: #fcfcfc;
$light_grey2: #c4c4c4;
$medium_grey: #e5e5e5;
$grey: #6d7d8b;
$dark_red: #c44241;
$pink: #e833aa;
$green: #66ba69;
$blue: #0694f2;
$light_blue: #e1f3ff;
$dark_green: #3e9b77;
$yellow: #fda860;
$purple: #7d43f8;
