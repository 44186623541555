@import '../../styles/index.scss';

.mobile-item {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  padding: 120px;
  height: 440px;
  background-color: $light_blue;

  @include up-to($tablet-large) {
    flex-direction: column;
    padding: 20px;
    height: 500px;
  }

  &.noRightPadding {
    padding-right: 0;

    @include up-to($tablet-large) {
      height: 440px;
    }
  }

  &:nth-child(even) {
    flex-direction: row-reverse;
    background-color: $white;

    @include up-to($tablet-large) {
      flex-direction: column;
    }
  }

  &-left {
    flex: 1;
  }

  &-title {
    color: $blue;
    font-size: 40px;
  }

  .url {
    margin-top: 4px;
    display: inline-block;
    color: rgba($black, 0.7);
  }

  &-subtitle {
    margin-top: 30px;
    font-size: 18px;
    width: 90%;
  }

  .url-cta {
    margin-top: 18px;
    font-size: 18px;
    display: inline-block;
  }

  .insight-btn {
    margin-top: 30px;
    border: 1px solid $green;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 12px 38px;
    font-size: 16px;
    background: transparent;
  }

  &-right {
    flex: 0.5;
    // max-width: 280px;
    // max-height: 280px;
    // // position: absolute;
    // top: -70px;
    // right:200px;

    @include up-to($tablet-large) {
      flex: 1;
    }

    .img-container {
      // right: 160px;
      position: absolute;
      top: -180px;

      @include up-to($tablet-large) {
        position: relative;
        top: 0;
      }

      &:nth-child(even) {
        right: 30px;
      }

      .img-wrap {
        // min-width: 240px;
        // min-height: 440px;

        @include up-to($tablet-large) {
          max-width: 200px;
          margin: 0 auto;
        }

        img {
          width: 100%;
          height: 100%;
        }

        // .other-img{
        //   position: absolute;
        //   object-fit: contain;
        //   width: 100%;
        //   height: 100%;
        // }
      }
    }
  }

  .two-img-container {
    display: flex;
    position: absolute;
    align-items: center;
    top: 55%;
    transform: translateY(-50%);
    right: 0;

    @include up-to($tablet-large) {
      left: 34%;
      transform: translateX-(50%);
      // margin-top:30px;
      // top:35%;
    }

    img {
      flex: 1;
    }

    .first-img {
      position: absolute;
      height: 80%;
      width: 80%;
      object-fit: contain;
      z-index: 2;
      left: -136px;
      top: 30px;

      @include up-to($tablet-large) {
        width: 80%;
        height: 80%;
        max-width: 200px;
        left: -80px;
      }
    }

    .second-img {
      width: 60%;
      height: 60%;

      @include up-to($tablet-large) {
        width: 100%;
        height: 100%;
        max-width: 280px;
      }
    }
  }
}
