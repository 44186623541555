@import '../../styles/index.scss';

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 99;

  &-backdrop {
    background-color: rgba($black, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    // z-index: 99;
  }

  &-body {
    height: 90%;
    width: 75%;
    min-width: 320px;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    background-color: $white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    z-index: 999;
    overflow-y: auto;

    @include up-to($tablet-large) {
      width: 80%;
    }

    @include up-to($phone-landscape) {
      width: 90%;
    }
  }
}
