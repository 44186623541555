@import './responsive.scss';
@import './colors.scss';
@import './fonts.scss';

.three-col-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

  @include up-to($tablet-small) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include up-to($phone-landscape) {
    grid-template-columns: 1fr;
  }
}

.two-col-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  @include up-to($phone-landscape) {
    grid-template-columns: 1fr;
  }
}
