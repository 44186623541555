@import '../../styles/index.scss';

.drawer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &-backdrop {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba($black, 0.7);
  }

  &-body {
    top: 0;
    right: 0;
    width: 30%;
    height: 100%;
    z-index: 999;
    padding: 10px;
    min-width: 280px;
    position: absolute;
    background-color: $dark_blue;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);

    @include up-to($phone-landscape) {
      width: 70%;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    padding: 0 10px;

    a {
      color: $white;
      font-size: 32px;
      // text-align: center;
      border-bottom: 1px solid rgba($white, 0.2);
      padding: 20px 10px;

      @include up-to($tablet-small) {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}
