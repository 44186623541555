$phone-landscape: 580px;
$tablet-small: 780px;
$tablet-medium: 849px;
$tablet-large: 1026px;
$desktop-medium: 1280px;
$desktop-large: 1440px;

@mixin up-from($size) {
  @media (min-width: $size) {
    @content;
  }
}

@mixin up-to($size) {
  @media (max-width: #{$size - 1px}) {
    @content;
  }
}
