@import '../../styles/index.scss';

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 80px;
  background-color: $dark_blue;

  @include up-to($tablet-small) {
    padding: 16px 24px;
  }

  &-logo {
    width: 100%;
    height: 100%;
    max-width: 150px;
    max-height: 150px;

    @include up-to($tablet-small) {
      max-width: 108px;
    }
  }

  &-links {
    @include up-to($tablet-large) {
      display: none;
    }

    a {
      color: $white;
      margin: 0 12px;
      font-size: 18px;
    }

    .join-now-btn {
      border: 1px solid $white;
      padding: 4px 12px;
      border-radius: 4px;
    }
  }

  .hamburger {
    border: none;
    cursor: pointer;
    background-color: transparent;

    @include up-from($tablet-large) {
      display: none;
    }

    span {
      width: 33px;
      height: 4px;
      display: block;
      cursor: pointer;
      margin-bottom: 5px;
      position: relative;
      border-radius: 3px;
      background: #cdcdcd;

      &:last-child {
        margin-bottom: 0px;
      }

      @include up-to($tablet-small) {
        width: 24px;
        height: 2px;
      }
    }
  }
}
