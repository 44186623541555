@import '../../styles/index.scss';

.grade-card {
  border-radius: 0px 30px 30px 30px;
  background: $white;

  .top-info {
    padding: 24px;
  }

  &-img {
    height: 100%;
    width: 100%;
    border-radius: 0px 30px 30px 30px;
  }

  .title {
    margin-top: 20px;
    font-size: 28px;
    color: $dark_blue;
  }

  .subtitle {
    margin-top: 12px;
    font-size: 16px;
    line-height: 22px;
  }

  .subject-lessons {
    padding: 0 30px 30px 0;
  }

  .subject-lessons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  .subject-pill {
    border-radius: 0px 10px 10px 0px;
    background-color: $dark_red;
    flex: 0.7;
    max-width: 190px;
    padding: 10px 0;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    &-img {
      height: 32px;
      width: 32px;
      object-fit: contain;
    }

    &-name {
      margin-left: 20px;
      font-size: 20px;
    }
  }

  .lessons-link {
    font-size: 18px;
    color: $primary_blue;
    margin-right: 6px;

    .chevron-right {
      margin-left: 5px;
      margin-top: 6px;
      position: absolute;
    }
  }
}
