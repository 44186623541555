@import '../../styles/index.scss';

.topic-card {
  background-color: #fff;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 24px;
  padding-bottom: 40px;

  &-title {
    color: $green;
    font-size: 20px;
    margin-bottom: 18px;
  }

  .subtopic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px 0;

    .name {
      font-family: $font-semibold;
    }
  }

  .see-more {
    color: $green;
    font-family: $font-semibold;
    margin-top: 40px;
    text-align: right;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 20px;
  }
}
